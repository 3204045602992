export default {
  getLinks: (state) => state.links,
  getFormBuilders: (state) => state.formBuilders,
  getFormBuildersPagination: (state) => state.formBuildersPagination,
  getFormBuildersLoading: (state) => state.formBuildersLoading,
  getTemplates: (state) => state.templates,
  getTemplatesPagination: (state) => state.templatesPagination,
  getTemplatesLoading: (state) => state.templatesLoading,
  getFormFlow: (state) => state.formFlow,
  getListVariables: (state) => state.listVariables,
  isEnabled: (state) => state.formFlow.enabled,
  getSignerLinks: (state) => state.signerLinks,
  getSignersList: (state) => state.signersList,
};
