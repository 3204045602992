import { axios } from '@/plugins';
import { snakeCase } from 'lodash';
import { SIGNER_CONSTANTS } from '@document/constants';
import {
  parseLinkSignerResponse,
  parsePhoneNumber,
  buildContactRequest,
  buildFlexibleContactRequest,
  buildSignAsListRequest,
  parseSigner,
} from '../../utils/signer-utils';

const createSigner = async (store, { url, payload }) => {
  try {
    const requestBody = parsePhoneNumber(payload);
    const response = await axios.post(url, {
      signer: requestBody,
      operator: !!requestBody.operator,
    });
    const signer = _.get(response, 'data');
    const parsedSigner = parseSigner(signer);
    return parsedSigner;
  } catch (err) {
    throw err;
  }
};

const linkSigner = async (_, { url, payload, context }) => {
  try {
    const requestBody = buildSignAsListRequest(payload, context);
    const { data } = await axios.post(url, requestBody);
    const signers = parseLinkSignerResponse(data, context);
    return { signers, lists: data.lists || data.signers };
  } catch (err) {
    throw err;
  }
};
const editSigner = async ({ rootGetters }, { url, signer, requirements, isFromChat }) => {
  if (isFromChat) {
    const payload = {
      refusable: signer.refusable,
      roles: requirements.newRoles,
      provideEvidences: requirements.provideEvidences.map(snakeCase),
      signerAttributes: signer,
    };
    try {
      const { data } = await axios.patch(url, payload);
      return { response: data };
    } catch (err) {
      return { response: {}, error: err };
    }
  } else {
    const { data } = await axios.patch(url, { signer });

    const documents = rootGetters['draft/getArchives'];

    if (!requirements.newRoles.length && !requirements.provideEvidences.length) return data;

    await Promise.all(
      documents.map(async (document) => {
        const payload = {
          requirements: {
            documentKey: document.key,
            roles: requirements.newRoles,
            provideEvidences: requirements.provideEvidences.map(snakeCase),
          },
        };
        return axios.put(data.signer.links.requirements, payload);
      })
    );
    return data;
  }
};

const linkFlexibleSigners = async ({ rootGetters }, { url, payload }) => {
  const { signers } = payload;
  const documents = rootGetters['draft/getArchives'];
  const signersResponse = await Promise.all(
    signers.map(async (signer) => {
      const signerInfo = {
        signer: {
          name: signer.name,
          email: signer.email,
          phoneNumber: signer.phoneNumber,
          birthday: signer.birthday,
          hasDocumentation: signer.hasDocumentation,
          documentation: signer.documentation,
          operator: !!signer.operator,
          communicateBy: signer.communicateBy,
          refusable: signer.features?.refusable,
          contact: signer.contact,
        },
      };
      const { data } = await axios.post(url, signerInfo);
      await Promise.all(
        documents.map(async (document) => {
          const provideEvidences =
            signer.authentications?.length > 0 ? signer.authentications : signer.auths;

          const requirements = {
            requirements: {
              documentKey: document.key,
              roles: signer.newRoles,
              provideEvidences: provideEvidences.map(snakeCase),
            },
          };
          return axios.post(data.signer.links.requirements, requirements);
        })
      );
      return data;
    })
  );
  return signersResponse;
};

const fetchSignerAuths = async ({ commit }, url) => {
  try {
    const response = await axios.get(url);
    commit('SET_SIGNER_AUTHS', _.get(response, 'data.auths'));
  } catch (err) {
    throw err;
  }
};

const fetchCommunicateBy = async ({ commit }, url) => {
  try {
    const response = await axios.get(url);
    commit('SET_COMMUNICATE_BY', _.get(response, 'data.communicateByOptions'));
  } catch (err) {
    throw err;
  }
};

const fetchSignerSignAs = async ({ commit }, url) => {
  try {
    const response = await axios.get(url);
    commit('SET_SIGNER_SIGN_AS', response?.data?.actions);
    commit('SET_NEW_SIGNER_SIGN_AS', response?.data?.newActions);
  } catch (err) {
    throw err;
  }
};

const fetchContacts = async ({ commit, state }, { url, params }) => {
  try {
    const response = await axios.get(url, { params });
    const contacts = _.castArray(_.get(response, 'data.contacts'));
    const parsedContacts = contacts.map(parseSigner);
    const contactsPayload = _.unionBy(params.page > 1 && state.contacts, parsedContacts, 'id');

    commit('SET_CONTACTS', contactsPayload);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const remindSignature = async (store, { url, message }) => {
  try {
    return await axios.post(url, { message });
  } catch (err) {
    return err;
  }
};

const notifySignature = async (store, { url, message }) => {
  try {
    return await axios.post(url, { message });
  } catch (err) {
    return err;
  }
};

const deleteContact = async ({ commit, getters }, payload) => {
  try {
    const url = _.get(payload, 'links.self');
    return axios.delete(url).then(() => {
      const nonDeletedContacts = getters.getContacts.filter((contact) => contact.id !== payload.id);
      commit('SET_CONTACTS', nonDeletedContacts);
    });
  } catch (err) {
    throw err;
  }
};

const updateContact = async ({ commit, rootGetters }, { url, payload, context }) => {
  try {
    let contact;
    const permittedContexts = SIGNER_CONSTANTS.CONTACT_FLEXIBLE_CONTEXTS;
    // TOGGLE_ENVELOPE_SETUP_SCOPE
    // TODO: when remove toggle delete const and uses
    const { toggleEnvelopeSetupScope } = rootGetters['featureFlags/getToggles'];

    if (toggleEnvelopeSetupScope && permittedContexts.includes(context)) {
      contact = buildFlexibleContactRequest(payload);
    } else {
      contact = buildContactRequest(payload);
    }
    const response = await axios.patch(url, { contact });
    const parsedContact = parseSigner(_.get(response, 'data.contact'));
    commit('UPDATE_CONTACT', parsedContact);
  } catch (err) {
    throw err;
  }
};

const createContact = async ({ commit, getters, rootGetters }, { url, payload, context }) => {
  try {
    let contact;
    const permittedContexts = SIGNER_CONSTANTS.CONTACT_FLEXIBLE_CONTEXTS;
    // TOGGLE_ENVELOPE_SETUP_SCOPE
    // TODO: when remove toggle delete const
    const { toggleEnvelopeSetupScope } = rootGetters['featureFlags/getToggles'];

    // TOGGLE_ENVELOPE_SETUP_SCOPE
    // TODO: Remove if and else blocks keep if inner code
    if (toggleEnvelopeSetupScope && permittedContexts.includes(context)) {
      contact = buildFlexibleContactRequest(payload);
    } else {
      contact = buildContactRequest(payload);
    }
    const response = await axios.post(url, { contact });
    const parsedContact = parseSigner(_.get(response, 'data.contact'));
    const allContacts = getters.getContacts;
    commit('SET_CONTACTS', [...allContacts, parsedContact]);
    return parsedContact;
  } catch (err) {
    throw err;
  }
};

const updateList = async (store, { url, message }) => {
  try {
    await axios.patch(url, { list: { message } });
  } catch (err) {
    throw err;
  }
};

const deleteList = async (store, url) => {
  try {
    await axios.delete(url);
  } catch (err) {
    throw err;
  }
};

const getSignatureMoreInfo = async (_, { url }) => {
  try {
    const { data } = await axios.get(url);
    return data.signature;
  } catch (err) {
    throw err;
  }
};

export default {
  createSigner,
  fetchSignerAuths,
  fetchSignerSignAs,
  fetchCommunicateBy,
  linkSigner,
  fetchContacts,
  deleteContact,
  remindSignature,
  notifySignature,
  updateContact,
  createContact,
  updateList,
  deleteList,
  getSignatureMoreInfo,
  linkFlexibleSigners,
  editSigner,
};
