import Vue from 'vue';
import Vuex from 'vuex';

import tracking from '@tracking/store/main';
import dashboard from '@dashboard/store/main';
import operators from '@operators/store/main';
import { nodes, draft, signer, downloads, document } from '@document/store';
import {
  info,
  api,
  consumption,
  membership,
  onboarding,
  subscription,
  security,
  audit,
  blocked,
  group,
  messageTemplates,
  invite,
} from '@account/store/';
import {
  templates,
  templateSetup,
  forms,
  formFlow,
  spreadsheetFlow,
  flow,
  disabledFlow,
  flowSetup,
  flowProcess,
  pendingAnswers,
  formBuilder,
} from '@flow/store';
import { fieldCreator } from '@field-creator/store';
import { chat } from '@ai/store';
import positionStamp from '@position-stamp/store/main';
import auth from '@auth/store/main';
import report from '@report/store/main';
import { environment } from '@base/store';
import acceptanceTerm from '@acceptance-term/store/main';
import signerArea from '@signer-area/store/main';
import envelope from '@envelope/store/main';
import envelopeSetup from '@envelope/store/envelope-setup/main';
import workspaces from '@workspaces/store/main';

import state from './state';
import mutations from './mutations';
import actions from './actions';

import consumptionLegacy from './modules/consumption/main';
import brand from './modules/brand/main';
import template from './modules/template/main';
import featureFlags from './modules/featureFlags/main';

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    brand,
    document,
    envelope,
    envelopeSetup,
    template,
    templates,
    templateSetup,
    forms,
    formFlow,
    flowSetup,
    spreadsheetFlow,
    pendingAnswers,
    flow,
    disabledFlow,
    flowProcess,
    fieldCreator,
    report,
    tracking,
    dashboard,
    nodes,
    operators,
    signer,
    downloads,
    draft,
    info,
    api,
    consumption,
    consumptionLegacy,
    membership,
    onboarding,
    subscription,
    auth,
    environment,
    security,
    audit,
    acceptanceTerm,
    signerArea,
    blocked,
    featureFlags,
    workspaces,
    group,
    messageTemplates,
    invite,
    chat,
    formBuilder,
    positionStamp,
  },
});

export default store;
export const useStore = () => store;
